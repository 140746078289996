<template>
    <div class="modal" v-if="modelValue" :class="{active : modelValue}">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>{{ title }} <span>Import {{ title }}</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <div class="form_grp">
                        <div class="group_item">
                            <label class="input_label">Paste Share Link Here</label>
                            <Form v-slot="{ errors }" ref="import-share-link-form">
                                <div class="field_wpr has_prefix" :class="{ 'has-error': errors.url }">
                                    <Field autocomplete="off" type="text" name="url" v-model="form.url" placeholder="https://superfitcoaching.com" rules="required|url" />
                                    <span class="prefix">URL</span>
                                </div>
                                <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                <ErrorMessage name="url" class="text-danger" />
                            </Form>
                        </div>
                    </div>
                    <div class="capsule_elm" v-if="hasSavedStyling">
                        <h5 class="large">Use Saved Styling?</h5>
                        <label for="saved_styling" class="switch_option capsule_btn">
                            <input type="checkbox" id="saved_styling" v-model="form.use_default" :true-value="1" :false-value="0" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                </div>
            </div>
            <div class="modal_footer">
                <button :disabled="loader" type="button" class="btn cancel_btn" @click="closeModal">Cancel</button>
                <button :disabled="loader" type="button" class="btn save_btn" @click="handleImportShareLink"><i class="fa fa-spinner fa-spin" v-if="loader"></i> {{ loader ? 'Importing' : 'Import' }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import { Field, Form, ErrorMessage } from 'vee-validate'

    export default {
        name: 'Import Share Link',

        data () {
            return {
                form: {
                    url: '',
                    use_default: 0,
                },
            }
        },

        props: {
            modelValue: Boolean,
            title: String,
            refreshAsset: Function,
            module: {
                type: String,
                default: ''
            },
            hasSavedStyling: {
                type: Boolean,
                default: false,
            },
        },

        emit: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        watch: {
            modelValue (value) {
                if (value) {
                    const vm = this;
                    vm.form = { url: '', use_default: 0 };

                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
        },

        computed: mapState({
            loader: state => state.commonModule.loader,
        }),

        methods: {
            ...mapActions({
                importShareLink: 'commonModule/importShareLink'
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleImportShareLink () {
                const vm = this;

                const form = vm.$refs['import-share-link-form'];

                form.validate().then((result) => {
                    if (result.valid) {
                        vm.form.setFieldError = form.setFieldError;
                        vm.form.title         = vm.module ? vm.module : vm.title;

                        vm.importShareLink(vm.form).then((result) => {
                            if (result) {
                                vm.refreshAsset();
                                vm.closeModal();
                            }
                        });
                    }
                });
            }
        }
    }
</script>
